import React from "react"
import * as Icon from "react-feather"
import {Monitor} from "../components/icons/imhIcons";

function perms(role, allowedRoles) {
  const cmode = localStorage.getItem('cmode', null);

  if(cmode === null) {
    return allowedRoles.includes(role);
  }

  return allowedRoles.includes("clientManager");
}

const navigationConfig = [
   {
        id: 'dashboard',
        title: 'Dashboard',
        type:"item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
          return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/dashboard"
    },
    {
        id: "users",
        title: "Users",
        type: "item",
        icon: <Icon.CheckSquare size={20}/>,
        permissions: (role) => {
            return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/users",
    },
    {
        id: "orders",
        title: "Orders",
        type: "item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
            return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/orders",
    },
    {
        id: "cleaner_orders",
        title: "Cleaner Campaigns",
        type: "item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
            return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/cleaner_orders",
    },
    {
        id: "votes_orders",
        title: "Votes Campaigns",
        type: "item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
            return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/votes_orders",
    },
    {
        id: "invoices",
        title: "Invoices",
        type: "item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
            return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/invoices",
    },
    {
        id: "refunds",
        title: "Refunds",
        type: "item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
            return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/refunds",
    },
    {
        id: "problematic_products",
        title: "Problematic Products",
        type: "item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
            return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/problematic_products",
    },
    {
        id: "products_on_check",
        title: "Products on check",
        type: "item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
            return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/products_on_check",
    },
    {
        id: "easybill-custom-invoices",
        title: "Easybill Custom Invoices",
        type: "item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
          return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/easybill_custom_invoices"
    },
    {
        id: "subscription-logs",
        title: "Subscription logs",
        type: "item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
          return perms(role, ["superadmin", "admin", "manager"]);
        },
        navLink: "/admin/subscription_logs"
    },
    {
        id: "reporting-dashboard",
        title: "Reporting Dashboard",
        type: "item",
        icon: <Monitor size={25}/>,
        permissions: (role) => {
          return perms(role, ["clientManager"]);
        },
        navLink: "/dashboard"
    }
];

export default navigationConfig
