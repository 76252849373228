import {toast} from "react-toastify";
import moment from "moment";

const initialState = {
    total_amount: 0,
    selected_rows: {},
    orders_paginate:[],
    orders_paginate_total:0,
};


const votesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_VOTES_ORDERS_PAGINATE":
      return {...state, orders_paginate: action.orders, orders_paginate_total:action.total, total_amount: action.total_amount};
    case "TRANSFER_VOTE_ORDER":
      if(action.payload.status == 'success'){
        toast.success('Order Transferred Successful!');
      }else{
        toast.error('Order Transferred Error!');
      }
      return state;
    case "COMPLETE_VOTES_ORDER":
      if(action.payload.status){
        toast.success('Order Completed Successful!');
      }else{
        toast.error('Order Completed Error!');
      }
      return state;
    case "SELECT_VOTES_ORDER":
      let selectedRows = {};
      if(action.check){
        selectedRows = Object.assign({}, action.row, state.selected_rows);
      } else {
        selectedRows = {...state.selected_rows};
        let key = Object.keys(action.row)[0];
        delete selectedRows[key];
      }
        return {...state, selected_rows: selectedRows}
    case "RESET_SELECT_VOTES_ORDER":
      return {...state, selected_rows: {}}

    case "EXPORT_VOTES_SELECTED_ROWS": {
      let csv;
      const columnDelimiter = ',';
      const lineDelimiter = '\n';
      const keys = [ 'Customer Name', 'Customer Profile Link', 'Marketplace', 'Review link', 'Amount of votes'];
      csv = '';
      csv += keys.join(columnDelimiter);
      csv += lineDelimiter;
      let obj = {...state.selected_rows}
      // console.log(obj)
      let obj_keys = Object.keys(obj);
      obj_keys.map(key => {
        let item = obj[key];
        let details = item.details;
        if(details.length){
          details.map(detail => {
            csv += `${ item.user_name },${ `//${window.location.host}/admin/clients/edit?id=${ item.user_id }` },${ item?.product?.market || ''},${detail.link},${ detail.votes },${ lineDelimiter }`;
          })
        }
      })

      const link = document.createElement('a');
      if (csv == null) return state;
      const filename = 'VotesCampaignsExport.csv';
      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }
      link.setAttribute('href', encodeURI(csv));
      link.setAttribute('download', filename);
      link.click();

      return state;
    }
    default:
      return state
  }
};

export default votesReducer
