import { combineReducers } from "redux"
import notificationReducer from "./notification/"
import customizer from "./customizer/"
import auth from "./auth/"
import usersReducer from "./users";
import ordersReducer from "./orders";
import cleanerReducer from "./cleaner";
import votesReducer from "./votes";
import productsReducer from "./products";
import refundsReducer from "./refunds";
import clientReducer from "./clients";
import authorizedUserReducer from "./user";
import dashboardReducer from "./dashboard";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  notification: notificationReducer,
  authorizedUser: authorizedUserReducer,
  users: usersReducer,
  orders: ordersReducer,
  cleaner_orders: cleanerReducer,
  votes_orders: votesReducer,
  products: productsReducer,
  refunds: refundsReducer,
  clients: clientReducer,
  dashboard: dashboardReducer
});

export default rootReducer
