import { history } from "@src/history"
import axios from "axios"
import { apiErrorHandler }  from '@utils/apiErrorHandler';


export const loginWithJWTReset = user => {
  return dispatch => {
    dispatch({
        type: "RESET_LOGIN"
    });
  }
};

export const loginWithJWT = user => {
  return dispatch => {
    axios
      .post(`/api/auth/login`, {
        email: user.email,
        password: user.password
      })
      .then(response => {

        if (response.data) {
          let user = response.data.data.user;

          dispatch({
            type: "FETCH_AUTHORIZED_USER_INFO",
            user: user
          });
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('access_token', response.data.data.access_token);
            console.log(['response.data.data.access_token', response.data.data.access_token])
            axios.defaults.headers.common = {Authorization: `Bearer ${response.data.data.access_token}`}

            let redirect = '/dashboard'
            if(user.role === 'superadmin' || user.role === 'admin' || user.role === 'manager'){
                redirect = "/admin/dashboard";
            }else if(user.role.name === 'adminManager'){
                redirect = "/admin/clients-and-ads";
            }
          history.push(redirect)
        }
      })
      .catch(err => {
          console.log(err)
        dispatch(apiErrorHandler(err));
      });
  }
};

export const logoutWithJWT = () => {
  return dispatch => {
      axios
          .post("/api/auth/logout", {})
          .then(response => {
                localStorage.removeItem('user')
                localStorage.removeItem('access_token')
                localStorage.clear();
              history.push('/')
          })
          .catch(err => {
            localStorage.clear();
            dispatch(apiErrorHandler(err));
          });
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

export const resetPassword = email => {
  return dispatch => {
    axios
      .post("/api/v1/password/reset/send", {
        email: email,
      })
      .then(response => {
        if (response.data) {
          dispatch({
            type: "RESET_PASSWORD",
            reset: response.data.data
          });
        }
      })
      .catch(err => {
        dispatch(apiErrorHandler(err));
      });
  }
}
