import {toast} from "react-toastify";
import moment from "moment";

const initialState = {
    total_amount: 0,
    selected_rows: {},
    orders_paginate:[],
    orders_paginate_total:0,
};


const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CLEANER_ORDERS_PAGINATE":
      return {...state, orders_paginate: action.orders, orders_paginate_total:action.total, total_amount: action.total_amount};
    case "TRANSFER_CLEANER_ORDER":
      if(action.payload.status){
        toast.success('Order Transferred Successful!');
      }else{
        toast.error('Order Transferred Error!');
      }
      return state;
    case "CANCEL_CLEANER_ORDER":
      if(action.payload.status){
        toast.success('Order Canceled Successful!');
      }else{
        toast.error('Order Canceled Error!');
      }
      return state;
    case "COMPLETE_CLEANER_ORDER":
      if(action.payload.status){
        toast.success('Order Completed Successful!');
      }else{
        toast.error('Order Completed Error!');
      }
      return state;
    case "SELECT_CLEANER_ORDER":
      let selectedRows = {};
      if(action.check){
        selectedRows = Object.assign({}, action.row, state.selected_rows);
      } else {
        selectedRows = {...state.selected_rows};
        let key = Object.keys(action.row)[0];
        delete selectedRows[key];
      }
        return {...state, selected_rows: selectedRows}
    case "RESET_SELECT_CLEANER_ORDER":
      return {...state, selected_rows: {}}

    case "EXPORT_SELECTED_ROWS": {
      let csv;
      const columnDelimiter = ',';
      const lineDelimiter = '\n';
      const keys = ['Date', 'TL Order ID', 'Customer ID', 'Customer Email', 'Product Link', 'ASIN', 'Price', 'Payment Method', 'Payment Status'];
      csv = '';
      csv += keys.join(columnDelimiter);
      csv += lineDelimiter;
      let obj = {...state.selected_rows}
      // console.log(obj)
      let obj_keys = Object.keys(obj);
      obj_keys.map(key => {
        let item = obj[key];
        csv += `${moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')},${item.order.id},${item.user_id},${item.user_email},https://www.amazon.de/dp/${item.product.asin},${item.product.asin},${item.order.amount},${item.order.payment_method},${item.order.status}${lineDelimiter}`;
      })

      const link = document.createElement('a');
      if (csv == null) return state;
      const filename = 'CleanerCampaignsExport.csv';
      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }
      console.log(csv);
      link.setAttribute('href', encodeURI(csv));
      link.setAttribute('download', filename);
      link.click();

      return state;
    }
    default:
      return state
  }
};

export default clientReducer
