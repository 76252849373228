import {toast} from "react-toastify";
import {CLIENT_PATH} from "@configs/constants";

const initialState = {
    financials:{
        easybill_custom_invoices_total: 0,
        rms_open_amount: 0,
        total_amz_users: 0,
        total_new_users: 0,
        total_sales: {cashback:0, revenue:0},
        total_users: 0,
        unpaid_invoices_amount: 0,
        user_plans: {
            business:{users: 0, percent: 0},
            first:{users: 0, percent: 0},
            premium:{users: 0, percent: 0},
            standard:{users: 0, percent: 0}
        },
        users_with_plans: {users: 0, percent: 0}
    },
    users_chart_data:{
        users:[],
        plans:[],
        mrr:[],
    },
    revenue_chart_data:[],
    easybill_custom_invoices_paginate:[],
    easybill_custom_invoices_paginate_total:0,
    easybill_custom_invoices_total_amount: 0,
    logs_paginate:[],
    logs_paginate_total:0
};


const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LOGS_PAGINATE":
      return {...state, logs_paginate: action.list, logs_paginate_total:action.total};
    case "GET_EASYBILL_CUSTOM_INVOICES_PAGINATE":
      return {...state, easybill_custom_invoices_paginate: action.list, easybill_custom_invoices_paginate_total:action.total, easybill_custom_invoices_total_amount: action.total_amount};
    case "GET_FINANCIALS_DATA":
      return {...state, financials: action.data};
    case "UPDATE_LOG_NOTE":
      toast.success('Updated!')
      return state;
    case "DELETE_EASYBILL_CUSTOM_INVOICE":
      toast.success('Deleted!')
      return state;
    case "CREATE_EASYBILL_CUSTOM_INVOICE":
      toast.success('Created!')
      return state;
    case "UPDATE_EASYBILL_CUSTOM_INVOICES_STATUS":
      toast.success('Updated');
      return state;
    case "UPDATE_ERROR_EASYBILL_CUSTOM_INVOICES_STATUS":
        toast.error('Update error');
      return state;
    case "GET_USERS_CHART_DATA":
      return {...state, users_chart_data: action.data};
    case "GET_REVENUE_CHART_DATA":
      return {...state, revenue_chart_data: action.data};
    default:
      return state
  }
};

export default clientReducer
