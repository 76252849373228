import {toast} from "react-toastify";
import {CLIENT_PATH} from "@configs/constants";

const initialState = {
    client: {
        id: -1,
        company:{}
    },
    managers:[],
    admin_notes:[],
    client_campaigns:[],
    client_campaigns_total:0,
    client_products:[],
    client_products_total:0,
    client_orders:[],
    client_orders_total:0,
    client_refunds:[],
    client_refunds_total:0,
    review_logs:[],
    review_logs_total:0,
    token:null,
    campaign:{
        id: -1,
    }
};


const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DOWNLOAD_ORDER_INVOICE":
        const url = window.URL.createObjectURL(new Blob([action.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice.pdf`);
        document.body.appendChild(link);
        link.click();
      return state;
    case "SWITCH_TO_CLIENT":
        window.open(`${CLIENT_PATH}/switch/${action.token}`, "_blank")
      return state;
    case "GET_CLIENT_ORDERS_PAGINATE":
      return {...state, client_orders: action.data, client_orders_total:action.total};
    case "GET_CLIENT_REVIEWS_LOGS_PAGINATE":
      return {...state, review_logs: action.data, review_logs_total:action.total};
    case "GET_CLIENT_REFUNDS_PAGINATE":
      return {...state, client_refunds: action.data, client_refunds_total:action.total};
    case "GET_CLIENT_CAMPAIGNS_PAGINATE":
      return {...state, client_campaigns: action.data, client_campaigns_total:action.total};
    case "GET_CLIENT_PRODUCTS_PAGINATE":
      return {...state, client_products: action.data, client_products_total:action.total};
    case "DELETE_CLIENT":
      toast.success('Client Deleted!')
      return state;
    case "GET_MANAGERS":
      return {...state, managers: action.managers};
    case "GET_CLIENT":
      return {...state, client: action.client};
    case "GET_CLIENT_CAMPAIGN":
      return {...state, campaign: action.data};
    case "GET_ADMIN_NOTES":
      return {...state, admin_notes: action.data};
    case "DELETE_ADMIN_NOTES":
      toast.success('Note deleted!')
      return {...state, admin_notes: action.data};
    case "SAVE_ADMIN_NOTES":
      toast.success('Note saved!')
      return {...state, admin_notes: action.data}; 
    case "CODEWORD_ENABLED":
      toast.success('Codeword enabled!')
      return state;
    case "CODEWORD_DISABLED":
      toast.success('Codeword disabled!')
      return state;
    case "ADD_CLIENT":
      toast.success('Successfully Created!')
      return state;
    case "UPDATE_CLIENT":
      toast.success('Client changes saved!')
      return {...state, client: action.client};
    case "UPDATE_CLIENT_USER":
      toast.success('Client changes saved!')
      return {...state, client: action.client};
    case "UPDATE_CLIENT_ERROR":
      toast.error(action.error)
      return state;
    case "ADD_CLIENT_ERROR":
      let
          er_msg = action.error.message;
      if(action.error.hasOwnProperty('errors')){
          er_msg = action.error.errors[Object.keys(action.error.errors)[0]][0];
      }
      toast.error(er_msg)
      return state;
    case "UPDATE_CLIENT_USER_ERROR":
      toast.error(action.error)
      return state;
    case "SET_SUBSCRIBE":
      if(action.payload.status == 'success'){
        toast.success(action.payload?.message || 'Plan changed Successful!');
      }else{
        toast.error(action.payload?.message || 'Plan change error!');
      }
      return state;
    default:
      return state
  }
};

export default clientReducer
