import axios from "axios"

export const getClient = (id) => {
    return dispatch => {
        axios
            .get(`/api/admin/clients/${id}`)
            .then(response => {
                dispatch({
                    type: "GET_CLIENT",
                    client: response.data.data,
                })
            })
            .catch(err => console.log(err))
    }
};
export const getManagers = (only_with_users = false) => {
    return dispatch => {
        axios
            .get(`/api/admin/managers`, {params:{only_with_users:only_with_users}})
            .then(response => {
                dispatch({
                    type: "GET_MANAGERS",
                    managers: response.data.data,
                })
            })
            .catch(err => console.log(err))
    }
};
export const getClientCampaigns = (id, params) => {
    return dispatch => {
        axios
            .get(`/api/admin/clients/${id}/campaign/paginate`, {
                params: params
            })
            .then(response => {
                dispatch({
                    type: "GET_CLIENT_CAMPAIGNS_PAGINATE",
                    data: response.data.data,
                    total: response.data.meta.total,
                })
            })
            .catch(err => console.log(err))
    }
};


export const getCampaign = (id) => {
    return dispatch => {
        axios
            .get(`/api/admin/campaign/${id}`)
            .then(response => {
                dispatch({
                    type: "GET_CLIENT_CAMPAIGN",
                    data: response.data.data
                })
            })
            .catch(err => console.log(err))
    }
};

export const getClientProducts = (id, params) => {
    return dispatch => {
        axios
            .get(`/api/admin/clients/${id}/product/paginate`, {
                params: params
            })
            .then(response => {
                dispatch({
                    type: "GET_CLIENT_PRODUCTS_PAGINATE",
                    data: response.data.data,
                    total: response.data.meta.total,
                })
            })
            .catch(err => console.log(err))
    }
};

export const getAdminNotes = (id, params) => {
    return dispatch => {
        axios
            .get(`/api/admin/clients/${id}/admin-notes`, {
                params: params
            })
            .then(response => {
                dispatch({
                    type: "GET_ADMIN_NOTES",
                    data: response.data.data
                })
            })
            .catch(err => console.log(err))
    }
};

export const deleteAdminNote = (id, note_id) => {
    return dispatch => {
        axios
            .delete(`/api/admin/clients/${id}/admin-notes/${note_id}`)
            .then(response => {
                dispatch({
                    type: "DELETE_ADMIN_NOTES",
                    data: response.data.data
                })
            })
            .catch(err => console.log(err))
    }
};


export const saveAdminNote = (id, params, callback = null) => {
    return dispatch => {
        axios
            .post(`/api/admin/clients/${id}/admin-notes`, params)
            .then(response => {
                if(callback){
                    callback();
                }
                dispatch({
                    type: "SAVE_ADMIN_NOTES",
                    data: response.data.data
                })
            })
            .catch(err => console.log(err))
    }
};

export const getClientOrders = (id, params) => {
    return dispatch => {
        axios
            .get(`/api/admin/clients/${id}/orders/paginate`, {
                params: params
            })
            .then(response => {
                dispatch({
                    type: "GET_CLIENT_ORDERS_PAGINATE",
                    data: response.data.data,
                    total: response.data.meta.total,
                })
            })
            .catch(err => console.log(err))
    }
};

export const getClientRefunds = (id, params) => {
    return dispatch => {
        axios
            .get(`/api/admin/clients/${id}/refunds/paginate`, {
                params: params
            })
            .then(response => {
                dispatch({
                    type: "GET_CLIENT_REFUNDS_PAGINATE",
                    data: response.data.data,
                    total: response.data.meta.total,
                })
            })
            .catch(err => console.log(err))
    }
};

export const downloadInvoice = (order_id) => {
  return dispatch => {
    axios.get(`/api/admin/order/${order_id}/invoice`, {
        responseType: 'blob'
    }).then((response) => {
        dispatch({
            type: "DOWNLOAD_ORDER_INVOICE",
            data: response.data
        })
    }).catch(err => console.log(err))
  }
};

export const loginUnderClient = (id) => {
    return dispatch => {
        axios
            .post(`/api/v1/clients/${id}/login-under-client`)
            .then(response => {
                dispatch({
                    type: "LOGIN_UNDER_CLIENT"
                })
            })
            .catch(err => console.log(err))
    }
};

export const deleteClient = (id, callback=()=>{}) => {
    return dispatch => {
        axios
            .delete(`/api/admin/clients/${id}`)
            .then(response => {
                dispatch({
                    type: "DELETE_CLIENT",
                })
                callback();
            })
            .catch(err => console.log(err))
    }
};

export const addClient = (user, callback) => {
    let phone = user.phone.replace(/([ -.*+?^=!:${}()|\[\]\/\\])/g, "");
    return dispatch => {
        let data = {
            fname                : user.fname,
            lname                : user.lname,
            email                : user.email,
            phone                : phone,
            smscode              : user.smscode || '1001',
            password             : user.password,
            password_confirmation: user.password_confirmation,
         }
        axios
            .post(`/api/auth/signup`, data)
            .then(response => {
              console.log(response)
                dispatch({
                    type: "ADD_CLIENT",
                    client: response.data.data,
                })
                if(callback && typeof callback == 'function'){
                    callback()
                }
            })
            .catch(err => {
                console.log(err, err.response)
                dispatch({
                    type: "ADD_CLIENT_ERROR",
                    error: err.response.data,
                })
            })
    }
};

export const updateAsUser = (id, params, callback = null) => {
    if(params.phone){
        params.phone = params.phone.replace(/([ -.*+?^=!:${}()|\[\]\/\\])/g, "")
    }
    return dispatch => {
        axios
            .put(`/api/admin/clients/${id}`, params)
            .then(response => {
                if(callback){
                    callback()
                }
                dispatch({
                    type: "UPDATE_CLIENT_USER",
                    client: response.data.data,
                })
                return response.data
            })
            .catch(err => {
                dispatch({
                    type: "UPDATE_CLIENT_USER_ERROR",
                    error: err.response.data.errors[Object.keys(err.response.data.errors)[0]][0],
                })
            })
    }
}

export const updateClient = (id, params, callback = null) => {
    if(params.phone){
        params.phone = params.phone.replace(/([ -.*+?^=!:${}()|\[\]\/\\])/g, "")
    }
    return dispatch => {
        axios
            .put(`/api/admin/clients/${id}`, params)
            .then(response => {
                if(callback){
                    callback()
                }
                dispatch({
                    type: "UPDATE_CLIENT",
                    client: response.data.data,
                })
                return response.data
            })
            .catch(err => {
                dispatch({
                    type: "UPDATE_CLIENT_ERROR",
                    error: err.response.data.errors[Object.keys(err.response.data.errors)[0]][0],
                })
            })
    }
};

export const switchToClient = (id) => {
    return dispatch => {
        axios
            .post(`/api/admin/clients/${id}/switch`)
            .then(response => {
                dispatch({
                    type: "SWITCH_TO_CLIENT",
                    token: response.data.token,
                })
            })
            .catch(err => console.log(err))
    }
};

export const codewordEnabled = (id) => {
    return dispatch => {
        axios
            .put(`/api/admin/clients/${id}/codeword_enabled`)
            .then(response => {
                dispatch({
                    type: "CODEWORD_ENABLED"
                })
            })
            .catch(err => console.log(err))
    }
};

export const codewordDisabled = (id) => {
    return dispatch => {
        axios
            .put(`/api/admin/clients/${id}/codeword_disabled`)
            .then(response => {
                dispatch({
                    type: "CODEWORD_DISABLED"
                })
            })
            .catch(err => console.log(err))
    }
};

export const setSubscribeClient = (id, plan, callback=null) => {
  return dispatch => {
    axios
      .post(`/api/admin/clients/${id}/subscribe`, {plan: plan})
      .then(response => {
        if(callback){
          callback();
        }
        console.log(response)
        dispatch({
          type: "SET_SUBSCRIBE",
          payload: response.data
        })
      })
      .catch(err => {
        dispatch({
          type: "SET_SUBSCRIBE",
          payload: {status: err.response.data.status, message: err.response.data.message}
        })
        console.log(err)}
      )
  }
}


export const getReviewLogs = (id, params) => {
  return dispatch => {
    axios
      .get(`/api/admin/clients/${id}/review-logs/paginate`, {
        params: params
      })
      .then(response => {
        console.log(response)
        dispatch({
          type: "GET_CLIENT_REVIEWS_LOGS_PAGINATE",
          data: response.data.data,
          total: response.data.total,
        })
      })
      .catch(err => console.log(err))
  }
}
